import { pre_btn } from "@/config/config";
import styles from './index.module.scss';

const TourModal = ({ next, skip, pre, index, des, onEnd, className }) => {
  return <div className={styles.tourModal}>
    <div className={styles.line}>
      <PointLine></PointLine>
    </div>
    <div className={styles.desc}>{des}</div>
    <div className={styles.footer}>
      {
        pre && <img onClick={pre} className={"w-[28px] h-[28px] cursor-pointer mr-[16px]"} src={pre_btn} />
      }
      {
        onEnd ?
          <div onClick={onEnd} className={"bg-linear font-bold w-[112px] h-[36px] cursor-pointer flex items-center justify-center text-[#fff] rounded-full"}>Get it</div> :
          <div onClick={next} className={"bg-linear font-bold w-[112px] h-[36px] cursor-pointer flex items-center justify-center text-[#fff] rounded-full"}>Next({index}/3)</div>
      }
    </div>
    {/* </div> */}
  </div>;
};


const PointLine = ({ className }) => {
  return <div className={`flex flex-col items-center ${className}`}>
    <div className={"rounded-full w-[8px] h-[7px] bg-linear"}></div>
    <div className={"h-[100px] w-[2px] bg-[#fff] rounded"}></div>
  </div>;
};


export default TourModal;
