import { Input, message } from "antd";
import { useEffect, useState } from 'react';
import gaTracker from "@/ga";
import { APIFeedback } from "@/api";
import { Icon } from "@iconify/react";
import styles from './index.module.scss';

const FeedbackPopup = ({ isVisible, title, closePopup }) => {
  const [checked, setChecked] = useState();  // 评分
  const [errMsg, setErrMsg] = useState(false);
  const [inputValue, setInputValue] = useState(""); // 输入内容
  const [selectedQue, setSelectedQue] = useState([]); // 选择的问题
  useEffect(() => {
    if (isVisible) {
      // 禁止页面滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 恢复页面滚动
      document.body.style.overflow = '';
    }
    // 在组件卸载时恢复页面滚动
    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);
  const scoreList = Array.from({ length: 10 }, (_, i) => i + 1);
  const question = [
    {
      id: 1,
      des: "Products Selection"
    },
    {
      id: 2,
      des: "Commission Increase"
    },
    {
      id: 3,
      des: "Sample Application"
    },
    {
      id: 4,
      des: "Earnings Analysis"
    },
    {
      id: 5,
      des: "Login & Authorization"
    },
    {
      id: 6,
      des: "Others"
    },
  ];

  const addQuestion = (id) => {
    if (selectedQue.includes(id)) {
      setSelectedQue([...selectedQue.filter(item => item !== id)]);
      return;
    }
    selectedQue.push(id);
    setSelectedQue([...selectedQue]);
  };

  const clearInfo = () => {
    setChecked();
    setErrMsg(false);
    setInputValue('');
    setSelectedQue([]);
  };
  const creatFeedBack = async (e) => {
    if (checked === undefined) {
      setErrMsg(true);
      return;
    }

    if (checked <= 5 && selectedQue.length <= 0) {
      setErrMsg(true);
      return;
    }
    gaTracker.trackEvent("home_feedback_submit");
    APIFeedback(JSON.stringify({
      score: checked,
      dissatisfied: checked > 5 ? '' : question.filter(item => selectedQue.includes(item.id)).map(item => item.des).join(","),
      suggestion: inputValue,
    }))
      .then(resp => {
        if (resp?.code === '1') {
          message.success("Successfully submitted your feedback, thanks!");
          closePopup();
          clearInfo();
        } else {
          message.info(resp?.message);
        }

      })
      .catch((e) => {
        message.info(e.message);
      });

  };


  return (
    isVisible && <div className={styles.overlay}
      onClick={(e) => {
        e.preventDefault();
        closePopup();
        clearInfo();
      }}
      onTouchMove={(e) => {
        e.preventDefault(); // 阻止默认的触摸移动行为，防止页面滚动
      }}>
      <div className={`${styles.popup} ${isVisible ? styles['popup-enter'] : styles['popup-exit']
        }`}
        onClick={(e) => e.stopPropagation()} // 阻止事件冒泡，避免点击内容关闭 Popup
      >
        <div className={styles.header}>
          <div className={styles.headerLeft}>{title}</div>
          <div className={styles.headerRight} onClick={() => { closePopup(); clearInfo(); }}>
            <Icon icon='line-md:close' className={styles.headerRightIcon} />
          </div>
        </div>
        <div className={styles.feedbackContent}>
          <div className={styles.scoreTitle}>
            <span className={styles.titleIcon}>*</span><span>How satisfied are you with the anyStarr Platform?</span>
          </div>
          <div className={styles.scoreWarp}>
            {scoreList.map((item, index) => (
              <div key={index} className={`${styles.scoreBox} ${checked === item && styles.scoreBoxActive}`} onClick={() => setChecked(item)}>{item}</div>
            ))}
          </div>
          <div className={styles.scoreTip}>
            <span>Not Satisfied</span><span>Most Satisfied</span>
          </div>
          {checked && checked <= 5 && <div>
            <div className={styles.scoreTitle}>
              <span className={styles.titleIcon}>*</span><span>What are you most dissatisfied with?</span>
            </div>
            <div>
              {
                question.map((item, index) => {
                  return <div onClick={() => addQuestion(item.id)} className={styles.question} key={index} >
                    <div className={`${styles.check_icon} ${selectedQue.includes(item.id) && styles.checked_icon}`}></div>
                    <div className={styles.questionText}>
                      {item.des}
                    </div>
                  </div>;
                })
              }
            </div>
          </div>}
          <div className={styles.scoreTitle}><span>What else can we do better?</span> </div>
          <div className={"mt-3 bg-[#EDEDED]"}>
            <Input.TextArea
              className={styles.scoreTextArea}
              onChange={(e) => setInputValue(e.target.value)}
              count={{
                show: true,
                max: 500,
              }}
              maxLength={500}
            >
            </Input.TextArea>
          </div>
        </div>

      </div>
      <div className={styles.footer} onClick={(e) => e.stopPropagation()} >
        {
          errMsg && <div className={styles.tip}>Please complete the questions marked with *</div>
        }
        <div className={styles.submit} onClick={creatFeedBack} >Submit</div>
      </div>
    </div>
  );
};

export default FeedbackPopup;