import { Spin, message, Tour, FloatButton, BackTop } from 'antd';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useRef } from 'react';
import gaTracker from "@/ga";
import { Icon } from "@iconify/react";
import useLogin from "@/hooks/useLogin";
import Empty from '@/components-mobile/Empty';
import LoginModal from "@/components/LoginModal";
import TourModal from "@/components-mobile/Tour";
import FeedbackPopup from "@/components-mobile/FeedbackPopup";
import ListCard from '@/components-mobile/ListCard';
import CommonLoading from "@/components/CommonLoading";
import { generateRandomStringWithTimestamp } from "@/utils/utils";
import { APIGetLinkByPid, APIH5ClickStatistics, APIGetGuideFinish, APIGetUserInfo } from "@/api";
import styles from './index.module.scss';
import Condition from './components/condition';
import SearchBar from '../../components-mobile/Home/search-bar';
import { fetchProductList, fetchAddCollect, fetchDeleteCollect } from '../../redux/actions/home-mobile';

const HomeMobile = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { needLogin, loginModalShow } = useLogin();
  const [mail, setMail] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [openId, setOpenId] = useState('');
  const [loading, setLoading] = useState(false);   // 页面触底loading
  const [page, setPage] = useState(1); // 加载页数，第一页已经加载
  const [more, setMore] = useState(false);  // 当加载5个页面后，手动加载一次
  const [hasLoaded, setHasLoaded] = useState(false);  // 标记数据是否加载完成
  const [showConnectTips, setShowConnectTips] = useState(false);  // 登录提示
  const [tourOpen, setTourOpen] = useState(false);  // 控制 Tour 弹窗显示的状态
  const [currentStep, setCurrentStep] = useState(0);
  const loadingRef = useRef(false); // 使用 ref 来标记是否正在加载
  const { productList, apiLoading, total, productListParams } = useSelector((state) => {
    return state.fetchProductList;
  });
  // 定义步骤，每个步骤包括 title, description, target
  const steps = [
    {
      description: <TourModal
        des={<div>
          <span className={"text-[#FF6E1F] font-bold"}> Find products  </span> by using recommendation button, search, filter, sort functions.
        </div>}
        index={1} next={() => setCurrentStep(currentStep + 1)} skip={() => setTourOpen(false)} />,
      target: () => document.querySelector("#step1"),
      nextButtonProps: null,
      prevButtonProps: null,
    },
    {
      description: <TourModal
        des={<div>
          <ul className={styles.tourCustomList}>
            <li>  Click on the card to view <span className={"text-[#FF6E1F] font-bold"}> product details  </span>.</li>
            <li> Click the "<span className={"text-[#FF6E1F] font-bold"}>+Add</span>" to add the products to your showcase.</li>
            <li> Light up the stars to add products to <span className={"text-[#FF6E1F] font-bold"}> collection </span> , and then "Batch Add to Showcase"!</li>
          </ul>
        </div>}
        className={"mr-[200px]"} index={2} next={() => setCurrentStep(currentStep + 1)} pre={() => setCurrentStep(currentStep - 1)} skip={() => setTourOpen(false)} />,
      target: () => document.querySelector("#step2"),
      nextButtonProps: null,
      prevButtonProps: null,
    },
    {
      description: <TourModal des={
        <div>
          Click here to view <span className={"text-[#FF6E1F] font-bold"}> E-Commerce Data </span> , <span className={"text-[#FF6E1F] font-bold"}> Showcase Products </span> and <span className={"text-[#FF6E1F] font-bold"}> Collection </span>.
        </div>
      } index={3} onEnd={() => { setTourOpen(false); APIGetGuideFinish(); }} pre={() => setCurrentStep(currentStep - 1)} />,
      target: () => document.querySelector("#step3"),
      nextButtonProps: null,
      prevButtonProps: null,
    }
  ];

  useEffect(()=>{
    if (loginModalShow){
      setShowConnectTips(loginModalShow);
    }
  }, [loginModalShow]);

  // 页面加载时调用接口
  useEffect(() => {
    const randomNum = localStorage.getItem("randomNum") ?? generateRandomStringWithTimestamp();
    if (!localStorage.getItem("randomNum")) {
      localStorage.setItem("randomNum", randomNum);
    }
    let params;
    if (productListParams?.sort === 5) {
      params = { page: 1, uuid: randomNum };
    } else {
      params = { page: 1 };
    }
    // 判断是否需要引导信息
    const token = localStorage.getItem("token");
    if (token) {
      APIGetUserInfo().then(resp => {
        if (resp.data.user?.needGuide) {
          setTourOpen(true);
        }
      });
    }
    dispatch(fetchProductList({ ...productListParams, ...params }));
  }, []);
  useEffect(() => {
    console.log('~11111bbbb', productList);
    // 如果加载的数据量已经达到总数，停止加载更多
    if (productList.length >= total) {

      loadingRef.current = true;
      setLoading(false);
    } else {
      setLoading(true);
      loadingRef.current = false;
    }
    if (total === 0 && productListParams.page === 1) {
      setHasLoaded(true);
    } else {
      setHasLoaded(false);
    }
  }, [productList]);
  // 监听滚动事件
  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      // 判断是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight - 100) {
        loadMoreData();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [productListParams, loading]);

  useEffect(() => {
    if (tourOpen) {
      document.body.style.overflow = "hidden"; // 禁止滚动
    } else {
      document.body.style.overflow = ""; // 允许滚动
    }
    return () => {
      document.body.style.overflow = ""; // 组件卸载时恢复
    };
  }, [tourOpen]);

  // 手动触发加载更多
  useEffect(() => {
    if (more) {
      loadingRef.current = false;
      loadMoreData();
    }
  }, [more]);

  const loadMoreData = () => {
    if (loadingRef.current) return;
    loadingRef.current = true; // 标记为正在加载
    setLoading(true);
    setPage(productListParams.page + 1);
    // 翻五页后手动触发一次
    if (productListParams.page % 5 === 0 && !more) return;
    setMore(false);
    const randomNum = localStorage.getItem("randomNum") ?? generateRandomStringWithTimestamp();
    if (!localStorage.getItem("randomNum")) {
      localStorage.setItem("randomNum", randomNum);
    }
    let params;
    if (productListParams?.sort === 5) {
      params = { page: productListParams.page + 1, uuid: randomNum };
    } else {
      params = { page: productListParams.page + 1 };
    }
    dispatch(fetchProductList({ ...productListParams, ...params }));
  };

  const goSearchPage = () => {
    router.push({
      pathname: '/search-page-mobile/'
    });
  };

  const collectChange = (e, data, collected) => {
    e.stopPropagation();
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (needLogin) {
      setShowConnectTips(true);
      return;
    }
    if (collected) {
      gaTracker.trackEvent("home_remove_collect");
      dispatch(fetchDeleteCollect({ uid: user.id, itemId: data.productId }));
    } else {
      gaTracker.trackEvent("home_collect");
      dispatch(fetchAddCollect({ uid: user.id, itemId: data.productId }));
    }
  };
  const addChange = (e, data) => {
    gaTracker.trackEvent("home_add_showcase");
    APIH5ClickStatistics({ productIds: data?.productId.toString() }).then().catch();
    e.stopPropagation();
    if (needLogin) {
      setShowConnectTips(true);
      return;
    }
    try {
      if (data.needApplyLink) {
        APIGetLinkByPid(data.productId).then(resp => {
          if (resp?.data.url) {
            const url = resp.data.url;
            window.location.href = url;
            console.log('~url', url);
          } else {
            message.info("Sorry, The product has been taken down!");
          }
        }).catch(e => {
          message.error("Sorry, Unknown exception!");
        }).finally(() => {
        });
      } else {
        const url = data.url;
        window.location.href = url;
      }
    } catch (e) {
      message.error("Sorry, Unknown exception!");
    }
  };
  const goDetailPage = (data) => {
    gaTracker.trackEvent("home_product_card");
    router.push(`/details-mobile/${data.productId}`);
  };
  const openEmail = () => {
    gaTracker.trackEvent("home_email");
    const email = 'contact@anystarr.com';
    const subject = 'To anystarr';

    // 使用mailto协议打开默认的电子邮件客户端
    // 打开邮件客户端
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  };
  const openFeedback = () => {
    gaTracker.trackEvent("home_feedback_click");
    let user = localStorage.getItem("user");
    user = JSON.parse(user);
    if (needLogin) {
      setShowConnectTips(true);
      return;
    }
    setFeedback(true);
  };
  // const baseURL = "aweme://webview?url=";
  // const targetURL = "https%3A%2F%2Finapp.tiktokv.com%2Falliance%2Fecom%2Ffe%2Fh5%2Fdefault%2Ffe_teu_h5_alliance%2Fpartner-notifications%2Fcombined-mcn-bind%3Ftrans_status_bar%3D1%26new_page_type%3D11%26source_page_type%3D%257B%257BsourcePageTypePlaceholder%257D%257D%26source_enter_from%3DsourceEnterFromPlaceholder%26source_enter_method%3DsourceEnterMethodPlaceholder%26enter_from%3Dec_notification%26enter_method%3DenterMethodPlaceholder%26relation_record_id%3D%26relation_id%3D8651360076329816874%26is_ticket_auth%3D1\u0026hide_nav_bar=1\u0026hybrid_sdk_version=bullet\u0026disableBounces=1\u0026webview_clear_color=1\u0026container_color_auto_dark=1\u0026show_loading=0\u0026should_full_screen=1\u0026use_spark=1";

  // // 1. 先对 URL 进行 `encodeURIComponent` 编码
  // const normalURL = decodeURIComponent(targetURL);


  // // 2. 拼接成完整 Deep Link
  // const deepLink = baseURL + normalURL;
  return (
    <div className={styles.homeMobile}>
      <div id='step1' >
        <SearchBar onPress={goSearchPage} productListParams={productListParams} />
        <Condition />
      </div>
      <Spin spinning={apiLoading && page === 1} indicator={<CommonLoading />}>
        <div className={styles.content} >
          {productList.length !== 0 && productList.map((item, index) => (
            <ListCard sourceData={item} key={index} openId={openId} typePage='home' collectChange={collectChange} goDetailPage={goDetailPage} addChange={addChange}
              onPress={(e, item) => {
                e.stopPropagation();
                if (item?.productId === openId || item?.productId === undefined) {
                  setOpenId('');
                } else {
                  setOpenId(item.productId);
                }
              }} />
          ))}
          {page !== 1 && loading && ((page - 1) % 5 !== 0
            ? <div className={"flex items-center justify-center"}><CommonLoading className={"w-[30px] h-[30px]"} /></div>
            : <div className={styles.seeMore} onClick={() => setMore(true)}>See More</div>
          )}
          {hasLoaded && <Empty result={`Sorry，there are no results for “${productListParams.searchName ? productListParams.searchName : 'unknown'}”`} />}
        </div>
      </Spin>
      <Icon icon='solar:document-add-bold' className={styles.docuIcon} onClick={openFeedback} />
      <Icon icon='solar:chat-line-bold' className={styles.mailIcon} onClick={openEmail} />
      <LoginModal open={showConnectTips} mobile={true} onCancel={() => setShowConnectTips(false)}></LoginModal>
      {/* 使用 Tour 组件 */}
      <Tour closeIcon={false} disabledInteraction={true} gap={{ radius: currentStep === 2 ? 20 : 4 }} steps={steps} open={tourOpen} current={currentStep} arrow={false} onClose={() => setTourOpen(false)} />
      {tourOpen && currentStep !== 2 && <div className={styles.skip} onClick={() => { setTourOpen(false); APIGetGuideFinish(); }} >
        <div className={"w-[86px] h-[28px] cursor-pointer flex items-center justify-center text-[#fff] border border-[#fff] rounded-full"}>Skip</div>
      </div>}
      <FeedbackPopup isVisible={feedback} closePopup={() => setFeedback(false)} title={'anyStarr Feedback'}></FeedbackPopup>
    </div>
  );
};
export default HomeMobile;
