import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import gaTracker from "@/ga";
import { Icon } from "@iconify/react";
import Popup from '@/components-mobile/Popup';
import SortInfo from '@/components-mobile/Home/sort';
import FilterInfo from '@/components-mobile/Home/filter';
import { generateRandomStringWithTimestamp } from "@/utils/utils";
import { fetchProductList, fetchCategoryFirst } from '@/redux/actions/home-mobile';
import styles from './index.module.scss';



const ConditionMobile = () => {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(false);
  const [openSort, setOpenSort] = useState(false);
  const [resetFilter, setResetFilter] = useState(false);  // 通知filter清除数据
  const [getFilterData, setGetFilterData] = useState(false);  // 通知filter传参
  const [resetSort, setResetSort] = useState(false);      // 通知sort清除数据
  const [getSortData, setGetSortData] = useState(false);  // 通知sort传参
  const { categoryFirst } = useSelector((state) => {
    return state.categoryFirstInfo;
  });
  const { productListParams } = useSelector((state) => {
    return state.fetchProductList;
  });

  const getRecommendInfo = () => {
    const randomNum = localStorage.getItem("randomNum") ?? generateRandomStringWithTimestamp();
    if (!localStorage.getItem("randomNum")) {
      localStorage.setItem("randomNum", randomNum);
    }
    dispatch(fetchProductList({
      page: 1,      // 当前页
      pageSize: 20,     // 每页显示数量
      sort: 5,      // 默认筛选类型
      searchType: 2, // 默认搜索类型，1-link，2-name 
      searchName: '', // 默认搜索name
      sortType: 0,
      uuid: randomNum,
    }));
  };

  const sortConfirm = (data) => {
    const params = { page: 1, sort: data, sortType: data ? 2 : 0 };
    dispatch(fetchProductList({ ...productListParams, ...params, }));
    gaTracker.trackEvent("sort");
    setOpenSort(false);
    setGetSortData(false);
  };

  const filterConfirm = (list, rate, sale) => {
    const ids = [
      list.level0?.length > 0 ? list.level0.join(',') : '',
      list.level1?.length > 0 ? list.level1.join(',') : '',
      list.level2?.length > 0 ? list.level2.join(',') : ''
    ].filter(Boolean).join(',');
    const params = { page: 1, earnPerTypes: sale.join(','), commissionTypes: rate.join(','), categoryIds: ids };
    dispatch(fetchProductList({ ...productListParams, ...params, list }));
    gaTracker.trackEvent("filter");
    setOpenFilter(false);
    setGetFilterData(false);
  };

  // 页面加载时调用接口
  useEffect(() => {
    dispatch(fetchCategoryFirst());
  }, []);
  return (
    <div className={styles.filterMobile}>
      <div className={styles.recommend} onClick={() => { getRecommendInfo(); gaTracker.trackEvent("home_rec"); }}>
        <img className={styles.recommendIcon} src='/static/commendIcon.png' ></img>
        <span className={styles.recommendText} >Recommended For You</span>
      </div>
      <Icon icon='solar:filter-line-duotone'
        className={`${styles.filterIcon} ${productListParams?.categoryIds || productListParams?.earnPerTypes || productListParams?.commissionTypes ? styles.selected : ''}`}
        onClick={() => setOpenFilter(true)} />
      <Icon icon='solar:sort-from-bottom-to-top-bold-duotone'
        className={`${styles.filterIcon} ${productListParams?.sortType ? styles.selected : ''}`}
        onClick={() => setOpenSort(true)} />
      <Popup
        isVisible={openFilter}
        title='Filter'
        resetText='Reset'
        confirmText='Confirm'
        resetPopup={() => setResetFilter(true)}
        closePopup={() => setOpenFilter(false)}
        confirmPopup={() => setGetFilterData(true)}>
        <FilterInfo
          categoryFirst={categoryFirst}
          defaultFilter={productListParams}
          resetFilter={resetFilter}
          getFilterData={getFilterData}
          onDataChange={filterConfirm}
          onReset={() => setResetFilter(false)} />
      </Popup>
      <Popup
        isVisible={openSort}
        title='Sort By'
        resetText='Reset'
        confirmText='Confirm'
        resetPopup={() => setResetSort(true)}
        closePopup={() => setOpenSort(false)}
        confirmPopup={() => setGetSortData(true)}>
        <SortInfo
          defaultSort={productListParams?.sort}
          resetSort={resetSort}
          getSortData={getSortData}
          onDataChange={sortConfirm}
          onReset={() => setResetSort(false)} />
      </Popup>
    </div >
  );
};
export default ConditionMobile;